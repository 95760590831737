.App {
  text-align: center;
}

.MainPage-header {
  background-color: #161823;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Beian-link {
  color: #337385;
  font-size: 15px;
}

.top_nav_1 {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  color: #cac7c7;
  font-size: 40px;
}

.top_nav_2 {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: #7e7d7d;
  font-size: 25px;
}

.bottom_nav {
  display: flex;
  margin: 10px;
  justify-content: center;
  color: #cac7c7;
  font-size: 18px;
}

.SubSite-Link-Group {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
}

.SubSite-Link {
  position: relative;
  display: flex;
  background-color: rgba(226, 233, 227, 0.3);
  color: #eee8e8;
  width: 300px;
  height: 400px;
  justify-content: center;
  margin-right: 20px;
  text-align: center;
  border: 1px solid rgb(173, 167, 167);
  box-shadow: 1px 1px 1px grey;
}

.SubSite-Link:hover{
  background-color: rgba(226, 233, 227, 0.4);
}

.SubSite_Logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -130%);
  margin: auto;
  width: 100px;
  height: 100px;
}

.External-Link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  z-index: 0;
}
